<template>
  <div class="terms-raw" v-html="data">
  </div>
</template>

<script>
import { computed } from 'vue'
import { i18n } from '@/plugins/i18n/i18n'

export default {
  name: 'TermsRaw',
  setup() {
    const data = computed(() => i18n.t('login.loginTerms.content'))
    return { data }
  }
}
</script>

<style lang="postcss" scoped>
::v-deep ol {
  @apply !list-decimal !list-item
}
::v-deep li {
  @apply ml-5 mb-2 !list-decimal !list-item
}
::v-deep p {
  @apply !mb-2
}
</style>
