<template>
  <BaseElSelect
    v-model="syncModel"
    :multiple="multiple"
    filterable
    remote
    clearable
    reserve-keyword
    collapse-tags
    :placeholder="$t('login.group.placeholder')"
    :no-match-text="$t('common.select.noMatch.text')"
    :no-data-text="$t('common.table.empty.text')"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    :disabled="disabled"
    @change="$emit('change')"
    @clear="getOrgs"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="item in orgList"
      :key="item.id"
      :data-testid="`org-${item.name}`"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetOrgs } from '@/api/auth'
export default {
  name: 'ServiceSearch',
  props: ['model', 'type', 'multiple', 'disabled'],

  data: () => ({
    loading: false,
    orgList: [],
  }),

  computed: {
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  async mounted () {
    await this.getOrgs()
  },

  methods: {
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.orgList = []

      try {
        this.loading = true
        setTimeout(async () => {
          this.getOrgs(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getOrgs (query = null) {
      try {
        this.orgList = []
        setTimeout(async () => {
          const res = await GetOrgs({
            name: (query) || undefined,
            limit: 50,
          })
          this.orgList = res
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>

<style scoped lang="postcss">
::v-deep .el-input {
  @apply w-full;
}
</style>
