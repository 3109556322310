import { ref, getCurrentInstance, reactive, computed } from 'vue'

export const useRecaptcha = () => {
  const { proxy } = getCurrentInstance()
  const showV2Recaptcha = ref(false)
  const recaptchaVersionFailed = reactive({ v2: false, v3: false })
  const recaptchaVerifyToken = ref(null)
  const recaptchaVersionUse = computed(() => {
    if (recaptchaVersionFailed.v3) return 'v2'
    return 'v3'
  })

  const v3RecaptchaVerify = async (action) => {
    try {
      await proxy.$recaptchaLoaded()
      const token = await proxy.$recaptcha(action)
      recaptchaVerifyToken.value = token
    } catch (error) {
      window.$message.error('v3RecaptchaVerify error')
      console.log('v3RecaptchaVerify error', error)
    }
  }

  const v2RecaptchaVerify = async (token, callBack) => {
    recaptchaVerifyToken.value = token
    callBack()
  }

  const handleRecaptchaError = (errorCode) => {
    recaptchaVerifyToken.value = null
    if (errorCode === 'RECAPTCHA_V3_INCORRECT') {
      recaptchaVersionFailed.v3 = true
      showV2Recaptcha.value = true
      return true
    }
    return false
  }

  return {
    recaptchaVerifyToken,
    v3RecaptchaVerify,
    recaptchaVersionFailed,
    recaptchaVersionUse,
    showV2Recaptcha,
    v2RecaptchaVerify,
    handleRecaptchaError,
  }
}
